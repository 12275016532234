import rouletteBORD from "../../assets/images/thumbs_bgs.png";

export const style = {
  root: {
    maxWidth: "450px",
    width: "100%",
    backgroundColor: "#000000",
    height: "93vh",
    overflow: "hidden",
    marginTop: "auto",
    position: "relative",
    backgroundImage: `url(${rouletteBORD})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    padding: 1,
    // transform: 'rotate(-90deg) !important',
    // left: '22% !important',
  },
  blue: {
    "&>p": {
      background: '#5352FF',
      width: "100%",
      height: '100%',
      fontSize: "15px",
      fontWeight: 600,
      color: "white",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  red: {
    "&>p": {
      background: "red",
      width: "100%",
      height: '100%',
      fontSize: "15px",
      fontWeight: 600,
      color: "white",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  black: {
    "&>p": {
      background: "black",
      width: "100%",
      height: '100%',
      fontSize: "15px",
      fontWeight: 600,
      color: "white",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  bettable: {
    width: "55%",
    height: "70%",
    position: "absolute",
    right: "19.5%",
    top: "14%",
    transform: 'rotate(180deg)',
    zIndex: 11,
  },
  btn1: {
    padding: 0,
    borderRight: "1px solid white",
    // width: "20%",
    borderRadius: "0px",
    height: "100%",
    transform: "rotate(-180deg)",
    "&>p": {
      fontSize: "10px",
      fontWeight: 600,
      color: "white",
    },
  },
  btn2: {
    padding: 0,
    border: "1px solid white",
    // width: "33.33333%",
    borderRadius: "0px",
    // height: "25%",
    transform: "rotate(-180deg)",
    height: '100% !important',
    overflow: 'hidden !important'
  },
  btn3: {
    padding: 0,
    border: "1px solid white",
    // width: "100%",
    borderRadius: "0px",
    // fontSize: "10px",
    color: "white",
    fontWeight: 600,
    height: "100%",
    "&>p": {
      transform: "rotate(-270deg)",
      fontSize: "12px",
      fontWeight: 600,
      color: "white",
    },
  },
  btn4: {
    transform: "rotate(-180deg)",
    padding: 0,
    fontSize: "12px",
    fontWeight: 900,
    color: "white",
    position: "relative",
    left: "43%",
    background: "green",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: 'flex',
    alignItems: 'end',
    marginTop: '5px'
  },
  rotatingElement: { animation: "$rotateInfinite 4s linear infinite" },
  "@keyframes rotateInfinite": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
    
  },
  naiming: {
    background:
      "#6C10ED",
    width: "110px",
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "35%",
    right: "-5.5%",
    borderRadius: "5px",
    padding: '5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
    "&>p:nth-child(2)": {
      color: "white",
      fontSize: "11px",
      fontWeight: "600",
      padding: "2px 2px",
      background: "#0A001B",
      width: "95%",
      margin: "auto",
      marginBottom: "2px",
      borderRadius: " 5px",
      textAlign: 'center',
      mt: '3px',
    },
  },

  naiming6: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: "6.2%",
    left: "6.5%",
    borderRadius: "5px",
    textAlign: "center",
    border: '1px solid #CEAE5E',
    width: "90px",
    "&>p:nth-child(1)": {
      color: "black",
      fontSize: "10px",
      fontWeight: "600",
      padding: "4px",
      background: "#02FAFD",
      width: "100%",
      textAlign: 'center',
      lineHeight: '12px',
    },
  },

  naiming2: {
    background:
      "linear-gradient(to right,#BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)",
    width: "100px",
    transform: "rotate(90deg)",
    position: "absolute",
    top: "21%",
    left: "-6.1%",
    borderRadius: "13px 13px 5px 5px",
    clipPath: "polygon(5% 0, 95% 0, 100% 100%, 0% 100%)",
    "&>p:nth-child(1)": {
      color: "black",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
    "&>p:nth-child(2)": {
      color: "white",
      fontSize: "11px",
      fontWeight: "600",
      padding: "2px 2px",
      background: "#0849AC",
      width: "95%",
      margin: "auto",
      marginBottom: "2px",
      borderRadius: "13px 13px 5px 5px",
      clipPath: "polygon(3% 0, 97% 0, 100% 100%, 0% 100%)",
      textAlign: 'center',
    },
  },

  naiming3: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: "74.5%",
    left: "-7.5%",
    borderRadius: "5px",
    textAlign: "center",
    border: '1px solid #CEAE5E',
    width: "90px",
    opacity: '0',
    "&>p:nth-child(1)": {
      color: "black",
      fontSize: "10px",
      fontWeight: "600",
      padding: "4px 10px",
      background: "#02FAFD",
      width: "100%",
      textAlign: 'center',
      lineHeight: '12px',
    },
  },

  naiming4: {
    background: "red",
    width: "70px",
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "8.7%",
    left: "-6%",
    borderRadius: "2px",
    padding: '5px 0px',
    opacity: 0,
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      background: "red",
      borderRadius: "2px",
      textAlign: 'center;'
    },
  },

  naiming7: {
    background: 'red',
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "57.5%",
    left: "-8.3%",
    padding: "10px 32px",
    borderRadius: "5px",
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
  },

  naiming8: {
    background: 'red',
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "39.5%",
    left: "-8.1%",
    padding: "6px 32px",
    borderRadius: "5px",
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
  },

  naiming5: {
    background: '#BA903B',
    border: '1px solid white',
    position: "absolute",
    bottom: "10%",
    left: "1.5%",
    borderRadius: "5px",
    width: '75px',
    opacity: 1,
    "&>p:nth-child(1)": {
      color: "white",
      fontWeight: "600",
      textAlign: "center",
      padding: '9px 5px !important',
      width: '100%',
    },
  },


  countdownOuter: {
    position: "absolute",
    width: "55px",
    height: "50px",
    background: "#BA903Bs",
    top: "25%",
    left: "4%",
    border: "1px solid white",
    borderRadius: "10px",
    outline: "3px solid #BA903B",
    boxSizing: "border-box",
    transform: 'rotate(-90deg)',
  },
  winnerlooserouter: {
    padding: "2px",
    border: "2px solid #C9A553",
    position: "absolute",
    right: "0%",
    transform: "rotate(90deg)",
    top: "12%",
    width: "115px",
    borderRadius: "5px",
    background: 'black',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wunningamount: {
    padding: "10px",
    border: "2px solid #C9A553",
    position: "absolute",
    right: "-8%",
    transform: "rotate(90deg)",
    bottom: "42%",
    // width: "120px",
    borderRadius: "5px",
  },


  flex: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },

  winnerlooserouter3: {
    padding: "0px",
    position: "absolute",
    right: "2%",
    transform: "rotate(90deg)",
    top: "28.4%",
    width: "50px",
    height: "50px",
    borderRadius: "5px",
    background: "#6C10ED",
  },
  winnerlooserouter33: {
    padding: "0px",
    border: "2px solid #C9A553",
    position: "absolute",
    right: "12.5%",
    left: "55%",
    transform: "rotate(90deg)",
    top: "16.5%",
    width: "100px",
    height: "25px",
    borderRadius: "5px",
    background: "black",
  },
  winnerLooserList33: {
    width: "100%",
    "&>p": {
      fontSize: "10px",
      fontWeight: "600",
      color: "white",
      textAlign: "center",
    },
  },
  winnerlooserouter2: {
    padding: ' 8px 5px',
    position: 'absolute',
    width: '96%',
    borderRadius: '5px',
    backgroundColor: 'white',
    bottom: 0,
  },
  winnerLooserList: {
    width: "100%",
    "&>p:nth-child(1)": {
      fontSize: "15px",
      fontWeight: "600",
      color: "white",
      mb: '1px',
      textAlign: 'center',
      // transform: "rotate(90deg)",
    },
    "&>p:nth-child(2)": {
      fontSize: "15px",
      fontWeight: "600",
      color: "white",
      mb: '1px',
      textAlign: 'center',
      // transform: "rotate(90deg)",
    },
    "&>p:nth-child(3)": { fontSize: "15px", fontWeight: "600", color: "white", mb: '1px', textAlign: 'center', },
  },
  winnerLooserList2: {
    width: "80%",
    "&>p": {
      fontSize: "12px",
      fontWeight: "600",
      color: "white",
      textAlign: "center",
    },
  },
  winnerLooserList4: {
    width: "100%",
    "&>p": {
      fontSize: "11px",
      fontWeight: "500",
      color: "white",
    },
  },
  winnerLooserList3: {
    width: "100%",
    "&>p": {
      fontSize: "35px",
      fontWeight: "600",
      color: "white",
      textAlign: "center",
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  naiming10: {
    // transform: "rotate(90deg)",
    position: "absolute",
    bottom: "16.2%",
    left: "-5.5%",
    // top: "21.2%",
    // left: "-14.5%",

    borderRadius: "5px",
    textAlign: "center",
    border: '1px solid #CEAE5E',
    width: "60px",

    "&>p:nth-child(1)": {
      color: "black",
      fontSize: "10px",
      fontWeight: "600",
      padding: "3px",
      background: "#02FAFD",
      width: "100%",
      textAlign: 'center',
      lineHeight: '12px',
    },
  },

  naiming11: {
    // transform: "rotate(90deg)",
    position: "absolute",
    bottom: "16.2%",
    right: "3%",
    borderRadius: "5px",
    textAlign: "center",
    border: '3px solid #CEAE5E',
    width: "60px",
    "&>p:nth-child(1)": {
      color: "black",
      fontSize: "10px",
      fontWeight: "600",
      padding: "3px",
      background: "#02FAFD",
      width: "100%",
      textAlign: 'center',
      lineHeight: '12px',
    },
  },
  naiming12: {
    background: 'red',
    border: '1px solid white',
    position: "absolute",
    bottom: "24%",
    left: "1.5%",
    borderRadius: "5px",
    width: '75px',
    opacity: 1,
    padding: '8px 5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
  },
  naiming13: {
    background: 'red',
    border: '1px solid white',
    position: "absolute",
    bottom: "17%",
    left: "1.5%",
    borderRadius: "5px",
    width: '75px',
    opacity: 1,
    padding: '8px 5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  naiming100: {
    background:
      "#6C10ED",
    width: "110px",
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "52%",
    right: "-5.5%",
    borderRadius: "5px",
    padding: '5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
    "&>p:nth-child(2)": {
      color: "white",
      fontSize: "11px",
      fontWeight: "600",
      padding: "2px 2px",
      background: "#0A001B",
      width: "95%",
      margin: "auto",
      marginBottom: "2px",
      borderRadius: " 5px",
      textAlign: 'center',
      mt: '3px',
    },
  },
  naiming200: {
    background:
      "#6C10ED",
    width: "110px",
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "52%",
    right: "8.5%",
    borderRadius: "5px",
    padding: '5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
    "&>p:nth-child(2)": {
      color: "white",
      fontSize: "11px",
      fontWeight: "600",
      padding: "2px 2px",
      background: "#0A001B",
      width: "95%",
      margin: "auto",
      marginBottom: "2px",
      borderRadius: " 5px",
      textAlign: 'center',
      mt: '3px',
    },
  },
  naiming300: {
    background:
      "#6C10ED",
    width: "110px",
    transform: "rotate(90deg)",
    position: "absolute",
    bottom: "35%",
    right: "8.5%",
    borderRadius: "5px",
    padding: '5px',
    "&>p:nth-child(1)": {
      color: "white",
      fontSize: "10px",
      fontWeight: "600",
      textAlign: "center",
    },
    "&>p:nth-child(2)": {
      color: "white",
      fontSize: "11px",
      fontWeight: "600",
      padding: "2px 2px",
      background: "#0A001B",
      width: "95%",
      margin: "auto",
      marginBottom: "2px",
      borderRadius: " 5px",
      textAlign: 'center',
      mt: '3px',
    },
  },
  p13: { fontSize: '13px !important', fontWeight: '500 !important', color: 'white !important' },
  p15: { fontSize: '13px !important', fontWeight: '500 !important', color: 'white !important' },



  btncss: {
    position: 'relative',
    padding: '10px 22px',
    borderRadius: '6px',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    backgroundColor: '#7d2ae8',
    transition: 'all 0.2s ease',
    '&:active': {
      transform: 'scale(0.96)',
    },
    '&:before, &:after': {
      position: 'absolute',
      content: '""',
      width: '150%',
      left: '50%',
      height: '100%',
      transform: 'translateX(-50%)',
      zIndex: -1000,
      backgroundRepeat: 'no-repeat',
    },
    '&:hover:before': {
      top: '-70%',
      backgroundImage: `
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #7d2ae8 20%, transparent 30%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%)
    `,
      backgroundSize: `
      10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 
      10% 10%, 15% 15%, 10% 10%, 18% 18%
    `,
      backgroundPosition: '50% 120%',
      animation: 'greentopBubbles 0.6s ease',
    },
    '&:hover:after': {
      bottom: '-70%',
      backgroundImage: `
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%),
      radial-gradient(circle, #7d2ae8 20%, transparent 20%)
    `,
      backgroundSize: `
      15% 15%, 20% 20%, 18% 18%, 20% 20%, 
      15% 15%, 20% 20%, 18% 18%
    `,
      backgroundPosition: '50% 0%',
      animation: 'greenbottomBubbles 0.6s ease',
    },
    '@keyframes greentopBubbles': {
      '0%': {
        backgroundPosition: `
        5% 90%, 10% 90%, 10% 90%, 15% 90%, 
        25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%
      `,
      },
      '50%': {
        backgroundPosition: `
        0% 80%, 0% 20%, 10% 40%, 20% 0%, 
        30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%
      `,
      },
      '100%': {
        backgroundPosition: `
        0% 70%, 0% 10%, 10% 30%, 20% -10%, 
        30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%
      `,
        backgroundSize: '0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%',
      },
    },
    '@keyframes greenbottomBubbles': {
      '0%': {
        backgroundPosition: `
        10% -10%, 30% 10%, 55% -10%, 70% -10%, 
        85% -10%, 70% -10%, 70% 0%
      `,
      },
      '50%': {
        backgroundPosition: `
        0% 80%, 20% 80%, 45% 60%, 60% 100%, 
        75% 70%, 95% 60%, 105% 0%
      `,
      },
      '100%': {
        backgroundPosition: `
        0% 90%, 20% 90%, 45% 70%, 60% 110%, 
        75% 80%, 95% 70%, 110% 10%
      `,
        backgroundSize: '0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%',
      },
    },
  }
};




