import AnderBaherChart from "../pages/SattaMatka/game/AnderBaherChart";
import SattaChart from "../pages/SattaMatka/game/Chart";
import History from "../pages/SattaMatka/game/History";
import LocationChart from "../pages/SattaMatka/game/LocationChart";
import LocationListChart from "../pages/SattaMatka/game/LocationListChart";
import Satta from "../pages/SattaMatka/game/Satta";
import Sattagameplay from "../pages/SattaMatka/game/Sattagameplay";
import Test from "../pages/Test";
import Account from "../pages/account/Account";
import AddBankDetails from "../pages/account/Component/AddBankDetails";
import AddUPI from "../pages/account/Component/AddUpi";
import AddedBankDetailList from "../pages/account/Component/AddedBankDetailList";
import BatHistorys from "../pages/account/Component/BatHistory";
import BettingIncomeRule from "../pages/account/Component/BeetingRule";
import Feedback from "../pages/account/Component/Feedback";
import GameNotification from "../pages/account/Component/GameNotification";
import Gamestaticks from "../pages/account/Component/Gamestaticks";
import Languages from "../pages/account/Component/Language";
import LoginPassword from "../pages/account/Component/LoginPassword";
import Mail from "../pages/account/Component/Mail";
import Notification from "../pages/account/Component/Notification";
import ReferralIncomeRule from "../pages/account/Component/ReferralRule";
import SettingCenter from "../pages/account/Component/SettingCenter";
import TicketGenerate from "../pages/account/TicketGenerate";
import Activity from "../pages/activity/Activity";
import Register from "../pages/auth/register/Register";
import Dashboard from "../pages/dashboard/Dashboard";
import FundRequest from "../pages/dashboard/FundRequest";
import WithdrawalManual from "../pages/dashboard/WithdrawalManual";
import Withdrawl from "../pages/dashboard/Withdrawl";
import MainPageOFIncome from "../pages/income/MainPageOFIncome";
import CashbackIncome from "../pages/income/incomeSubSection/Cashback";
import LevelIncome from "../pages/income/incomeSubSection/LevelIncome";
import ReferralBonus from "../pages/income/incomeSubSection/ReferralBonus";
import Promotion from "../pages/promotion/Promotion";
import CustomerLine from "../pages/promotion/component/CustomerLine";
import Invitaton from "../pages/promotion/component/Invitaton";
import MyCommission from "../pages/promotion/component/MyCommission";
import PromotionRule from "../pages/promotion/component/PromotionRule";
import RobateRetio from "../pages/promotion/component/RebateRatio";
import Subordinate from "../pages/promotion/component/Subordinate";
import TeamData from "../pages/promotion/component/TeamData";
import TeamReports from "../pages/promotion/component/TeamReport";
import Wallet from "../pages/wallet/Wallet";
import DepositeHistory from "../pages/wallet/component/DepositeHistory";
import QRScreen from "../pages/wallet/component/QRScreen";
import WalletRecharge from "../pages/wallet/component/WalletRecharge";
import WithdravalHistory from "../pages/wallet/component/WithdravalHistory";
import Home from "../rollet/pages/Home/Home";

export const routes = [
  {
    path: "/rollet",
    component: <Home />,
  },
  {
    path: "/account",
    component: <Account />,
  },
  {
    path: "/withdravalHistory",
    component: <WithdravalHistory />,
  },
  {
    path: "/Withdrawal",
    component: <Withdrawl />,
  },
  {
    path: "/withdrawal-manual",
    component: <WithdrawalManual />,
  },
  {
    path: "/depositHistory",
    component: <DepositeHistory />,
  },
  {
    path: "/wallet/Recharge",
    component: <WalletRecharge />,
  },
  {
    path: "/satta/matka",
    component: <Satta />,
  },
  // {
  //     path: "/trx",
  //     component: <Trx />
  // },
  {
    path: "/wallet",
    component: <Wallet />,
  },
  {
    path: "/bathistory",
    component: <BatHistorys />,
  },
  {
    path: "/rulesponsor",
    component: <BettingIncomeRule />,
  },
  {
    path: "/rulereferral",
    component: <ReferralIncomeRule />,
  },
  {
    path: "/notification",
    component: <Notification />,
  },
  {
    path: "/gamestaticks",
    component: <Gamestaticks />,
  },
  {
    path: "/Language",
    component: <Languages />,
  },
  {
    path: "/SettingCenter",
    component: <SettingCenter />,
  },
  {
    path: "/SettingCenter/LoginPassword",
    component: <LoginPassword />,
  },
  {
    path: "/SettingCenter/mail",
    component: <Mail />,
  },
  {
    path: "/feedback",
    component: <Feedback />,
  },
  {
    path: "/gameNotification",
    component: <GameNotification />,
  },
  // {
  //     path:"/",
  //     component:<Login/>
  // },
  {
    path: "/test",
    component: <Test />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/activity",
    component: <Activity />,
  },
  // {
  //     path: "/win",
  //     component: <Win />
  // },
  {
    path: "/promotion",
    component: <Promotion />,
  },
  {
    path: "/promotion/Subordinate",
    component: <Subordinate />,
  },
  {
    path: "/promotion/PromotionShare",
    component: <Invitaton />,
  },
  {
    path: "/promotion/TeamReport/",
    component: <TeamReports />,
  },
  {
    path: "/promotion/TeamReport/data",
    component: <TeamData />,
  },
  {
    path: "/promotion/MyCommission/",
    component: <MyCommission />,
  },
  {
    path: "/promotion/PromotionRule/",
    component: <PromotionRule />,
  },
  {
    path: "/promotion/RebateRatio/",
    component: <RobateRetio />,
  },
  {
    path: "/promotion/customerLine/",
    component: <CustomerLine />,
  },
  {
    path: "/view_fund_request",
    component: <FundRequest />,
  },
  {
    path: "/account/income-main",
    component: <MainPageOFIncome />,
  },
  {
    path: "/account/income-main/referral-bonus",
    component: <ReferralBonus />,
  },
  {
    path: "/account/income-main/level-income",
    component: <LevelIncome />,
  },
  {
    path: "/account/income-main/cashback-income",
    component: <CashbackIncome />,
  },

  {
    path: "/add-bank-details",
    component: <AddBankDetails />,
  },
  {
    path: "/add-upi-details",
    component: <AddUPI />,
  },
  {
    path: "/add-bank-details/pre-added-bank-details",
    component: <AddedBankDetailList />,
  },
  {
    path: "/deposit/qr-screen",
    component: <QRScreen />,
  },
  {
    path: "/SattaChart",
    component: <SattaChart />,
  },
  {
    path: "/satta/play",
    component: <Sattagameplay />,
  },
  {
    path: "/history",
    component: <History />,
  },
  {
    path: "/anderbaher/chart",
    component: <AnderBaherChart />,
  },
  {
    path: "/location/chart/list",
    component: <LocationListChart />,
  },
  {
    path: "/location/chart",
    component: <LocationChart />,
  },
  {
    path: "/query",
    component: <TicketGenerate />,
  },
];
