
export const getSattaType = [
    {
        type:1,
        name:"GHAZIABAD"
    },
    {
        type:2,
        name:"FARIDABAD"
    },
    {
        type:3,
        name:"GALI"
    },
    {
        type:4,
        name:"DESAWAR"
    },
]
export const getSattaTypeForAPI = [
    {
        type:1,
        name:"satta_gaziabad"
    },
    {
        type:2,
        name:"satta_faridabad"
    },
    {
        type:3,
        name:"satta_gali"
    },
    {
        type:4,
        name:"satta_disawar"
    },
]